import React from 'react';
import { render } from 'react-dom';
import { css, Global } from '@emotion/core';

import { BaseStyleInjector } from '@deltablue/react-components';
import { sans, serif, special, mono } from '@deltablue/react-components/dist-font/font-faces';

import TotalPrice from './TotalPrice';


const rootId = 'app-root';

// add root container to body
const rootElem = document.createElement('div');
rootElem.id = rootId;
document.body.appendChild(rootElem);

// bootstrap app to DOM
render(
    <React.Fragment>
        <Global styles={
            css`
                ${sans}
                ${serif}
                ${special}
                ${mono}
                body, html {
                    height: 100%;
                }
                #${rootId} {
                    min-height: 100vh;
                    height: 100%;
                }
            `}
        />
        <BaseStyleInjector />
        <TotalPrice />
    </React.Fragment>,
    document.getElementById(rootId),
);
