import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { colors, fonts, Flex, useInputProps } from '@deltablue/react-kit';
import TotalValue from './presentational/TotalValue';
import NameField from './presentational/NameField';

const edgeGatewayDefaultPrice = 12.85;

const localStringArgs = [
    undefined,
    {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }
];

function EdgeGatewayPrice({ onChange }) {
    const [unitPriceProps] = useInputProps(edgeGatewayDefaultPrice)
    const [amountProps] = useInputProps(1);

    const edgeGatewaysPrice = unitPriceProps.value * amountProps.value;

    useEffect(() => {
        onChange(edgeGatewaysPrice)
    }, [edgeGatewaysPrice]);

    return (
        <TotalValue color={colors.brand.solid}>
            {{
                main: (
                    <Flex grow={1} direction='column' align='stretch' gutter='0.25rem'>
                        <Flex gutter='0.25rem' align='center' style={{ marginBottom: '0.5rem' }}>
                            <NameField
                                field={{
                                    value: `edge gateway${amountProps.value > 1 ? 's' : ''}`
                                }}
                                placeholder='gateway name'
                                style={{ flexGrow: 1 }}
                                disabled
                            />
                            <span style={{alignSelf: 'center', fontFamily: fonts.mono}}>&euro;</span>
                            <NameField
                                type='number'
                                step={0.01}
                                min={0}
                                style={{ width: '4.5em', fontWeight: 600, fontFamily: fonts.mono, fontSize: '0.875em', color: colors.general.bright }}
                                field={unitPriceProps}
                            />
                            <span style={{ fontSize: '1.5rem', opacity: 0.5 }}>&times;</span>
                            <NameField
                                type='number'
                                min={0}
                                style={{ width: '2.75em', fontWeight: 300, fontFamily: fonts.mono }}
                                field={amountProps}
                            />
                        </Flex>
                    </Flex>
                ),
                total: (
                    <span>
                        &euro;{edgeGatewaysPrice.toLocaleString(...localStringArgs)}
                    </span>
                ),
            }}
        </TotalValue>
    );
}

EdgeGatewayPrice.propTypes = {
    onChange: PropTypes.func,
};

EdgeGatewayPrice.defaultProps = {
    onChange: () => { },
};

export default EdgeGatewayPrice;
