import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { colors, fonts, Flex, Range, useInputProps } from '@deltablue/react-kit';
import ApplicationPrice from './ApplicationPrice'
import TotalValue from './presentational/TotalValue'
import AddButton from './presentational/AddButton';
import NameField from './presentational/NameField';
import EdgeGatewayPrice from './EdgeGatewayPrice';
import WithCalcLine from './presentational/WithCalcLine';
import VariablePriceLine from './presentational/VariablePriceLine';
import RemoveButton from './presentational/RemoveButton';


const hoursPerDay = 24;
const daysPerMonth = 31;

const localStringArgs = [
    undefined,
    {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }
];

function EnvironmentPrice({
    onChange,
    onClickDelete,
}) {
    const [gatewaysPrice, setGatewaysPrice] = useState(0);
    const [applicationPrices, setApplicationPrices] = useState({ 0: null });
    const [envNameProps] = useInputProps('new environment');
    const [hoursPerDayProps] = useInputProps(hoursPerDay);
    const [daysPerMonthProps] = useInputProps(daysPerMonth);

    function handleGatewayPriceChange(gatewaysPrice) {
        setGatewaysPrice(gatewaysPrice);
    }

    function handleApplicationPriceChange(applicationKey, applicationPrice) {
        setApplicationPrices(applicationPrices => ({
            ...applicationPrices,
            [applicationKey]: applicationPrice,
        }))
    }

    function addApplication() {
        setApplicationPrices(applicationPrices => ({
            ...applicationPrices,
            [Object.keys(applicationPrices).length + 1]: null,
        }))
    }

    let containersPrice = gatewaysPrice;
    for (const containerKey of Object.keys(applicationPrices)) {
        containersPrice += applicationPrices[containerKey] || 0;
    }

    const hoursPerDayMultiplier = hoursPerDayProps.value / hoursPerDay;
    const daysPerMonthMultiplier = daysPerMonthProps.value / daysPerMonth;
    const optimisedPrice = (containersPrice * hoursPerDayMultiplier * daysPerMonthMultiplier);

    useEffect(() => {
        onChange(optimisedPrice)
    }, [optimisedPrice]);

    return (
        <TotalValue color={colors.brand.darker}>
            {{
                main: (
                    <Flex style={{ position: 'relative' }} grow={1} direction='column' align='stretch' gutter='1rem'>
                        <RemoveButton
                            style={{
                                position: 'absolute',
                                top: '-0.5rem',
                                right: '-0.5rem',
                            }}
                            onClick={onClickDelete}
                        />
                        <Flex grow={1} direction='column' align='stretch' gutter='0rem'>
                            <NameField
                                field={envNameProps}
                                placeholder='environment name'
                                style={{ margin: '0.75rem' }}
                            />
                            <Flex style={{ padding: '0.75rem' }} direction='column' align='stretch' gutter='0.5rem'>
                                <EdgeGatewayPrice
                                    onChange={handleGatewayPriceChange}
                                />
                                {Object.keys(applicationPrices).map(containerKey => (
                                    applicationPrices[containerKey] !== undefined && (
                                        <ApplicationPrice
                                            key={containerKey}
                                            onChange={containerPrice => handleApplicationPriceChange(containerKey, containerPrice)}
                                            onClickDelete={() => handleApplicationPriceChange(containerKey, undefined)}
                                        />
                                    )
                                ))}
                                <AddButton color={colors.brand.solid} onClick={addApplication}>add instance</AddButton>
                            </Flex>
                        </Flex>
                        <WithCalcLine sign='+' />
                        <Flex justify='end' align='center' style={{ fontSize: '1.75rem', fontWeight: 600, fontFamily: fonts.mono }}>
                            <span style={{ opacity: 0.75, fontSize: '0.625em', fontFamily: fonts.sans }}>
                                monthly cost
                            </span>
                            <span style={{marginLeft: '0.5em'}}>
                                &euro;{containersPrice.toFixed(2)}
                            </span>
                        </Flex>
                        <WithCalcLine />
                        <div style={{ padding: '0 1rem' }}>
                            <VariablePriceLine>
                                {{
                                    label: <div style={{ width: '6rem' }}>hours per day</div>,
                                    input: <Range min='1' max='24' unit='hours' {...hoursPerDayProps} />,
                                    calc: <span>&times; 1/24</span>,
                                    result: <span>{(hoursPerDayProps.value / 24).toLocaleString(...localStringArgs)}</span>,
                                }}
                            </VariablePriceLine>
                            <VariablePriceLine>
                                {{
                                    label: <div style={{ width: '6rem' }}>days per month</div>,
                                    input: <Range min='1' max='31' unit='days' {...daysPerMonthProps} />,
                                    calc: <span>&times; 1/31</span>,
                                    result: <span>{(daysPerMonthProps.value / 31).toLocaleString(...localStringArgs)}</span>,
                                }}
                            </VariablePriceLine>
                        </div>
                        <WithCalcLine sign='×' />
                    </Flex>
                ),
                total: (
                    <Flex justify='end' align='center' style={{ fontSize: '1.75rem', fontWeight: 600, fontFamily: fonts.mono, marginBottom: '0.125em' }}>
                        <span style={{ opacity: 0.75, fontSize: '0.625em', fontFamily: fonts.sans }}>
                            optimised monthly running cost
                        </span>
                        <span style={{marginLeft: '0.5em'}}>
                            &euro;{optimisedPrice.toFixed(2)}
                        </span>
                    </Flex>
                ),
            }}
        </TotalValue>
    );
}

EnvironmentPrice.propTypes = {
    onChange: PropTypes.func,
    onClickDelete: PropTypes.func,
};

EnvironmentPrice.defaultProps = {
    onChange: () => { },
    onClickDelete: () => { },
};

export default EnvironmentPrice;
