import React from 'react';
import { colors } from '@deltablue/react-kit';

function RemoveButton({
    children,
    style,
    ...props
}) {
    return (
        <button
            style={{
                height: '0.625rem',
                width: '0.625rem',
                fontSize: '0.75em',
                fontWeight: 700,
                border: 'none',
                margin: 0,
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                outline: 'none',
                borderRadius: 2,
                backgroundColor: colors.brand.light,
                color: colors.brand.darker,
                cursor: 'pointer',
                ...style,
            }}
            {...props}
        >
            {children || <span style={{ lineHeight: 0 }}>&times;</span>}
        </button>
    );
}

export default RemoveButton;
