import React from 'react';
import PropTypes from 'prop-types';
import { colors, Box, Flex, fonts } from '@deltablue/react-components';


function TotalValue({ children, color }) {
    return (
        <Box style={{ backgroundColor: color, color: colors.general.bright, boxShadow: 'none', overflow: 'visible', padding: '0.75rem' }}>
            {children.main}
            <Flex justify='end' style={{ paddingTop: '0.5rem', fontSize: '1em', fontWeight: 600, fontFamily: fonts.mono }}>
                {children.total}
            </Flex>
        </Box>
    )
}

TotalValue.propTypes = {
    children: PropTypes.shape({
        main: PropTypes.node,
        total: PropTypes.node,
    }),
    color: PropTypes.string,
};

TotalValue.defaultProps = {
    color: colors.brand.medium,
};

export default TotalValue;
