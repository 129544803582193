import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { colors, fonts, Flex } from '@deltablue/react-components';


const labelStyle = css`
    font-size: 0.875em;
    text-align: right;
`;

const calcStyle = css`
    color: ${colors.brand.fading};
    opacity: 0.5;
    font-size: 0.75em;
    font-family: ${fonts.mono};
`;

const resultStyle = css`
    color: white;
    font-size: 0.825em;
    text-align: right;
    font-family: ${fonts.mono};
`;

function VariablePriceLine({ children, width }) {
    return (
        <Flex align='center' style={{ width }} gutter='0.5rem'>
            <div css={labelStyle} style={{ minWidth: '3.65rem' }}>
                {children.label}
            </div>
            <Flex grow={1} direction='column' align='stretch' style={{ minWidth: '14rem' }}>
                {children.input}
            </Flex>
            <div css={calcStyle} style={{ minWidth: '3.5rem' }}>
                {children.calc}
            </div>
            <div css={resultStyle} style={{ minWidth: '4rem' }}>
                {children.result}
            </div>
        </Flex>
    );
}

VariablePriceLine.propTypes = {
    children: PropTypes.shape({
        label: PropTypes.node,
        input: PropTypes.node,
        calc: PropTypes.node,
        result: PropTypes.node,
    }),
    width: PropTypes.string,
};

VariablePriceLine.defaultProps = {
    width: 'auto',
};

export default VariablePriceLine;
