import React from 'react';
import { css } from '@emotion/core';
import { colors } from '@deltablue/react-components';


const style = (color, vertical) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    background-color: ${color};
    padding: 0.25em;
    text-align: center;
    border-radius: 0.25em;
    border: none;
    color: white;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
    div {
        display: flex;
        align-items: center;
    }
    ${vertical ? `
        div {
            writing-mode: vertical-rl;
            text-orientation: mixed;
        }
    ` : ''}
`;

function AddButton({ children, color, vertical = false, ...rest }) {
    return (
        <button css={style(color, vertical)} {...rest}>
            <div>
                <span style={{ color: colors.signal.positive, fontSize: '1.25em' }}>+&nbsp;</span>
                {children}
            </div>
        </button>
    );
}

export default AddButton;
