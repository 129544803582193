import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { colors, fonts, Flex, Range, useInputProps } from '@deltablue/react-kit';
import TotalValue from './presentational/TotalValue';
import NameField from './presentational/NameField';
import WithCalcLine from './presentational/WithCalcLine';
import VariablePriceLine from './presentational/VariablePriceLine';
import RemoveButton from './presentational/RemoveButton';


const localStringArgs = [
    undefined,
    {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }
];

const corePrice = 10.07; // per core
const memoryPrice = 10.79; // per GB
const storagePrice = 0.27; // per GB


function ApplicationPrice({
    name,
    onChange,
    onClickDelete,
}) {
    const [applicationNameProps] = useInputProps(name);
    const [amountProps] = useInputProps(1);
    const [coresProps] = useInputProps(1);
    const [memoryProps] = useInputProps(1);
    const [storageProps] = useInputProps(3);

    const coresValue = coresProps.value * corePrice;
    const memoryValue = memoryProps.value * memoryPrice;
    const storageValue = storageProps.value * storagePrice;

    const containerPrice = coresValue + memoryValue + storageValue;
    const applicationPrice = amountProps.value * containerPrice;

    useEffect(() => {
        onChange(applicationPrice);
    }, [applicationPrice]);

    return (
        <TotalValue color={colors.brand.solid}>
            {{
                main: (
                    <Flex style={{ position: 'relative' }} grow={1} direction='column' align='stretch' gutter='0.25rem'>
                        <RemoveButton
                            style={{
                                position: 'absolute',
                                top: '-0.5rem',
                                right: '-0.5rem',
                            }}
                            onClick={onClickDelete}
                        />
                        <Flex gutter='0.25rem' align='center' style={{ marginBottom: '0.5rem' }}>
                            <NameField
                                field={applicationNameProps}
                                placeholder='container name'
                                style={{ flexGrow: 1 }}
                            />
                            <span style={{ fontSize: '1.5rem', opacity: 0.5 }}>&times;</span>
                            <NameField
                                type='number'
                                min={0}
                                style={{ width: '2.75em', fontWeight: 300, fontFamily: fonts.mono }}
                                field={amountProps}
                            />
                        </Flex>
                        <VariablePriceLine>
                            {{
                                label: 'processor',
                                input: <Range min='1' max='256' unit='CORES' {...coresProps} />,
                                calc: <span>&times; &euro;{corePrice.toLocaleString(...localStringArgs)}</span>,
                                result: <span>&euro;{coresValue.toLocaleString(...localStringArgs)}</span>,
                            }}
                        </VariablePriceLine>
                        <VariablePriceLine>
                            {{
                                label: 'memory',
                                input: <Range min='1' max='2048' unit='GB' {...memoryProps} />,
                                calc: <span>&times; &euro;{memoryPrice.toLocaleString(...localStringArgs)}</span>,
                                result: <span>&euro;{memoryValue.toLocaleString(...localStringArgs)}</span>,
                            }}
                        </VariablePriceLine>
                        <VariablePriceLine>
                            {{
                                label: 'storage',
                                input: <Range min='3' max='2048' unit='GB' {...storageProps} />,
                                calc: <span>&times; &euro;{storagePrice.toLocaleString(...localStringArgs)}</span>,
                                result: <span>&euro;{storageValue.toLocaleString(...localStringArgs)}</span>,
                            }}
                        </VariablePriceLine>
                        <WithCalcLine sign='+' />
                    </Flex>
                ),
                total: <span>&euro;{containerPrice.toLocaleString(...localStringArgs)}</span>
            }}
        </TotalValue>
    );
}

ApplicationPrice.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    onClickDelete: PropTypes.func,
};

ApplicationPrice.defaultProps = {
    name: 'new instance',
    onChange: () => { },
    onClickDelete: () => { },
};

export default ApplicationPrice;
