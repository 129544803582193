import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { colors, Flex, Field, useInputProps } from '@deltablue/react-kit';
import EnvironmentPrice from './EnvironmentPrice';
import AddButton from './presentational/AddButton';


function ProjectPrice({ onChange }) {
    const [environmentPrices, setEnvironmentPrices] = useState({ 0: null });
    const [customerAmountProps] = useInputProps(1);

    function handleEnvironmentPriceChange(environmentKey, environmentPrice) {
        setEnvironmentPrices(environmentPrices => ({
            ...environmentPrices,
            [environmentKey]: environmentPrice,
        }));
    }

    function addSetup() {
        setEnvironmentPrices(environmentPrices => ({
            ...environmentPrices,
            [Object.keys(environmentPrices).length]: null,
        }));
    }

    let projectPrice = 0;
    for (const environmentKey of Object.keys(environmentPrices)) {
        projectPrice += environmentPrices[environmentKey] || 0;
    }

    const spreadPrice = projectPrice / customerAmountProps.value;

    useEffect(() => {
        onChange(spreadPrice);
    }, [spreadPrice]);

    return (
        <div>
            <div style={{ overflow: 'auto', padding: '1rem' }}>
                <Flex direction='row' align='stretch' gutter='0.5rem' style={{ minHeight: '16em' }}>
                    {Object.keys(environmentPrices).map(environmentKey => (
                        environmentPrices[environmentKey] !== undefined && (
                            <EnvironmentPrice
                                key={environmentKey}
                                onChange={environmentPrice => handleEnvironmentPriceChange(environmentKey, environmentPrice)}
                                onClickDelete={() => handleEnvironmentPriceChange(environmentKey, undefined)}
                            />
                        )
                    ))}
                    <AddButton
                        color={colors.brand.dark}
                        vertical
                        onClick={addSetup}
                    >
                        add environment
                    </AddButton>
                </Flex>
            </div>
            <Flex style={{ padding: '1rem' }} direction='column' align='end' gutter='0.5em'>
                <div>total setup cost: <strong style={{ fontWeight: 600 }}>&euro;{projectPrice.toFixed(2)}</strong></div>
                <Flex align='center' gutter='0.5em'>
                    <div>this setup services</div>
                    <Field
                        type='number'
                        min={0}
                        style={{ width: '5rem' }}
                        {...customerAmountProps}
                    />
                    <div>customers</div>
                </Flex>
                <div>cost: <strong style={{ fontWeight: 600 }}>&euro;{spreadPrice.toFixed(2)} / customer</strong></div>
            </Flex>
        </div>
    );
}

ProjectPrice.propTypes = {
    onChange: PropTypes.func,
};

ProjectPrice.defaultProps = {
    onChange: () => { },
};

export default ProjectPrice;
