import { hot } from 'react-hot-loader';
import React from 'react';
import ProjectPrice from './ProjectPrice';


function TotalPrice() {
    return (
        <ProjectPrice />
    );
}

export default hot(module)(TotalPrice)
