import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { colors, Flex } from '@deltablue/react-components';


const style = css`
    color: ${colors.brand.fading};
    font-size: 1em;
    font-weight: 500;
    opacity: 0.75;
    line-height: 0.75;
    align-self: flex-end;
    text-align: right;
`;

function WithCalcLine({ sign }) {
    return (
        <Flex align='center'>
            <Flex grow={1} style={{ height: '1px', backgroundColor: colors.brand.fading, opacity: 0.5, marginRight: '0.25rem' }} />
            <div css={style}>{sign}</div>
        </Flex>
    );
}

WithCalcLine.propTypes = {
    sign: PropTypes.string.isRequired,
};

export default WithCalcLine;
